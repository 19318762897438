import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddWorkOutWeekTabs from './AddWorkOutWeekTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import APILIST from '../../../Config/ApiList';
import AxiosInstance from '../../../Config/AxiosInstance';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
const AddWorkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [workoutPlanAllDetails, setWorkoutPlanAllDetails] = useState({});
  const [isOpenAddWorkOutModel, setIsOpenAddWorkOutModel] = useState(false);
  const [isSelectedDay, setIsSelectedDay] = useState(0);
  const [isSelectedDays, setIsSelectedDays] = useState(null);
  const [typeCheck, setTypeCheck] = useState(null);
  useEffect(() => {
    if (location?.state?.workOutPlanID) {
      const workOutPlanID = location.state.workOutPlanID;
      const checkType = location?.state?.type;
      if (workOutPlanID) {
        getWorkoutPlanDetails(workOutPlanID);
        setTypeCheck(checkType)
      }
    }
  }, [location?.state?.data]);
  const getWorkoutPlanDetails = async (workOutPlanID) => {
    try {
      const response = await AxiosInstance.get(APILIST.GET_WORKOUT_PLANS, { params: { id: workOutPlanID } });
      if (response && response?.data?.s) {
        const resData = response?.data?.r;
        setWorkoutPlanAllDetails(resData);
        const selectDay = parseInt(resData?.day);
        const selectedDays = [];
        for (let i = 1; i <= selectDay; i++) {
          selectedDays.push({ [i]: Array.from({ length: i }, (_, j) => j + 1) });
        }
        if (selectDay) {
          window.sessionStorage.setItem('planDay', JSON.stringify(selectedDays));
          window.sessionStorage.setItem('planDayNumber', selectDay);
          setIsSelectedDay(selectDay);
          setIsSelectedDays(selectedDays);
        }
      } else {
        setWorkoutPlanAllDetails({});
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  const handleSubmitWorkout = async () => {
    setIsOpenAddWorkOutModel(false);
    if (location?.state?.workOutPlanID) {
      const workOutPlanID = location.state.workOutPlanID;
      await getWorkoutPlanDetails(workOutPlanID);
    }
  };
  const handleDeletePlanDetailsUpdate = async () => {
    if (location?.state?.workOutPlanID) {
      const workOutPlanID = location.state.workOutPlanID;
      if (workOutPlanID) {
        getWorkoutPlanDetails(workOutPlanID);
      }
    }
  };
  const handleDeletePlandetailsWeekAllPosition = async (CheckD) => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete all workout details for the selected week? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const misMatches = CheckD.filter(elem => elem?.id);
        if (misMatches.length > 0) {
          const selectedIDs = misMatches.map(detail => detail.id).join(',');
          try {
            const params = { remove_ids: selectedIDs };
            console.log('params', params);
            const response = await AxiosInstance.post(APILIST.UPDATE_DELETE_WORKOUT_DETAILS, params);
            if (response.data?.s) {
              Swal.fire({
                title: "Deleted!",
                text: "All workouts for the selected week have been successfully deleted. You will be redirected shortly.",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                const runnerPID = location.state.workOutPlanID;
                if (runnerPID) {
                  getWorkoutPlanDetails(runnerPID);
                }
              });
            } else {
              toast.error("Deletion failed. Please try again.");
            }
          } catch (error) {
            toast.error("An error occurred while trying to delete the workouts. Please check your network connection and try again.");
          }
        } else {
          toast.info("No workouts found for the selected week to delete.");
        }
      }
    });
  };
  const handlePublishWorkout = async () => {
    if (workoutPlanAllDetails?.week_data) {
      const weekData = workoutPlanAllDetails.week_data;
      let hasPendingWorkouts = false;
      const expectedPositions = JSON.parse(sessionStorage.getItem("planDay")) || {};
      for (const week of weekData) {
        const uniqueDays = new Set();
        const workoutsForWeek = week?.workout_details || [];
        workoutsForWeek.forEach(workout => {
          uniqueDays.add(workout.day);
        });
        const uniqueDaysArray = Array.from(uniqueDays);
        const expectedPositionsArray = expectedPositions[uniqueDaysArray.length] || [];
        if (!Array.isArray(expectedPositionsArray)) {
          hasPendingWorkouts = true;
          break;
        }
        const isValid = expectedPositionsArray.every(day => uniqueDays.has(day));

        if (!isValid) {
          hasPendingWorkouts = true;
          break;
        }
      }

      if (hasPendingWorkouts) {
        await Swal.fire({
          title: "Pending Workout Details",
          text: "Some workout details are incomplete. Please fill in the missing details before publishing.",
          icon: "warning",
          confirmButtonColor: "#46adf2",
          confirmButtonText: "OK",
        });
      } else {
        try {
          // Prepare form data for API request
          const formData = new FormData();
          formData.append('id', location?.state?.workOutPlanID);
          formData.append("status", 1);

          const response = await AxiosInstance.post(APILIST.UPDATE_DELETE_WORKOUT, formData);

          // Check if response is successful
          if (response && response.data?.s) {
            await Swal.fire({
              title: "Workout Published",
              text: "The workout has been successfully published.",
              icon: "success",
              confirmButtonColor: "#46adf2",  // Optional: Customize the button color
              confirmButtonText: "Great!",  // Custom button text
              showConfirmButton: true,
              timer: 1500, // Optional auto-close after 1.5 seconds
            });

            window.sessionStorage.clear();  // Clear session storage
            navigate('/admin/workout');  // Navigate to workout list page
          } else {
            toast.error("An error occurred while trying to publish the workout. Please try again.");
          }
        } catch (error) {
          // Handle errors in the API call
          console.error(error);
          toast.error("An error occurred while trying to publish the workout. Please check your network connection.");
        }
      }
    } else {
      toast.error("Workout plan details are missing. Please reload and try again.");
    }
  };
  const handleOnSubmitDraft = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to submit the draft?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit it!',
      cancelButtonText: 'No, keep editing',
      confirmButtonColor: '#46adf2',
      cancelButtonColor: '#d33',
    });

    if (result.isConfirmed) {
      window.sessionStorage.clear();
      navigate('/admin/workout');
    } else {
      return;
    }
  };
  const handleBackNavigate = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Your changes will be saved as a draft. Do you want to go back?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, save as draft',
      cancelButtonText: 'No, keep editing',
      confirmButtonColor: '#46adf2',
      cancelButtonColor: '#d33',
    });
    if (result.isConfirmed) {
      window.sessionStorage.clear();
      navigate('/admin/workout');
    } else {
      return;
    }
  };
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
      }}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'fixed',
          top: '0px',
          left: '0px',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
          padding: '10px',
          zIndex: 100
        }}>
          <IconButton sx={{
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#101010',
            borderRadius: '8px',
            "&:hover": {
              backgroundColor: '#101010',
              color: '#fff',
            }
          }} onClick={() => handleBackNavigate()}>
            <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '28px' }} />
          </IconButton>
          <Box sx={{
            width: 'calc(100% - 240px)'
          }}>
            <Typography sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
              textAlign: "center",

            }}>{typeCheck === "Edit" ? "Update Workout" : "Add Workout"}</Typography>
          </Box>
          <Box sx={{
            width: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: "10px"
          }}>
            <CustomSubmitButton
              btnTitle={"Draft"}
              type="submit"
              onSubmit={() => handleOnSubmitDraft()}
            />
            <CustomSubmitButton
              btnTitle={typeCheck === "Edit" ? "Update" : "Publish"}
              type="submit"
              onSubmit={() => handlePublishWorkout()}
            />

          </Box>
        </Box>
        <Box sx={{
          background: "#fff",
          padding: '30px',
          margin: "100px auto 50px auto",
          width: "100%",
          position: 'relative',
          zIndex: 9,
          borderRadius: '10px'
        }}>
          <Box sx={{
            maxWidth: { xs: "100%", lg: "80%", xl: "70%" },
            margin: "auto auto",
            width: "100%",
          }}>
            <Box sx={{
              width: "100%",
            }}>
              <Box sx={{}}>
                <AddWorkOutWeekTabs
                  workoutPlanAllDetails={workoutPlanAllDetails}
                  handleSubmitWorkout={handleSubmitWorkout}
                  setIsOpenAddWorkOutModel={setIsOpenAddWorkOutModel}
                  isOpenAddWorkOutModel={isOpenAddWorkOutModel}
                  isSelectedDay={isSelectedDay}
                  isSelectedDays={isSelectedDays}
                  onDeleteUpdate={handleDeletePlanDetailsUpdate}
                  handleDeletePlandetailsDayAllPosition={handleDeletePlandetailsWeekAllPosition}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default AddWorkout