import { Avatar, Box, Button, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import moment from "moment/moment";
import Constant from "../../../Utils/Constant";
import Fire from "../../../Assets/Images/Common/strikeFire.png";
import UserGraph from "./UserGraph";
import DummyUserProfit from '../../../Assets/Images/Common/avatar-5.jpg'
const UserDetails = () => {
  const progress = (8 / 20) * 100;
  const location = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [userPlanDetails, setUserPlanDetails] = useState({});
  const [challengeEventDetails, setIsChallengesEventDetails] = useState([]);
  console.log('challengeEventDetails',challengeEventDetails);
  
  useEffect(() => {
    const userID = location?.state?.userID;
    if (userID) {
      getUserDetails(userID);
      getUserPlan(userID);
      getChalangeEventPlan(userID);
    }
  }, [location?.state?.userID])
  const getUserDetails = async (userID) => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_BY_ID, {
        params: { user_id: userID },
      });
      if (res && res?.data?.s === 1) {
        const userData = res?.data?.r;
        setUserDetails(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserPlan = async (userID) => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_MY_PLAN, {
        params: { user_id: userID },
      });
      if (res && res?.data?.s === 1) {
        setUserPlanDetails(res?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getChalangeEventPlan = async (userID) => {
    try {
      const res = await AxiosInstance.get(APILIST.GET_RUNNUNG_PLAN_HISTORY, {
        params: { user_id: userID },
      });
      if (res && res?.data?.s) {
        const eventData = res?.data?.r;
        setIsChallengesEventDetails(eventData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingX: "30px",
          paddingY: "15px",
        }}
      >
        {/* Header  */}
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center", mb: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon
              sx={{ fontSize: { xs: "22px", xl: "32px" }, color: "black" }}
            />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "Outfit",
              fontSize: { xs: "20px", xl: "32px" },
              fontWeight: "500",
              //   paddingBottom: "10px",
              color: "#000000",
            }}
          >
            User Details
          </Typography>
        </Box>
        <Box
          sx={{
            background: "#EFE9FF80",
            width: "100%",
            display: "flex",
            borderRadius: "15px",
            marginBottom: '10px'
          }}
        >
          <Box sx={{ width: "100%", display: "flex" }}>
            <Avatar sx={{ width: "80px", height: "80px", m: 2 }}>
              <img
                src={userDetails?.profile_img ? `${Constant.BASE_URL}${userDetails?.profile_img}` : DummyUserProfit}
                alt=""
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Avatar>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "16px", xl: "20px" }, fontWeight: "500" }}
              >
                UserName: {`${userDetails?.user_name || "N/A"}`}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "16px", xl: "20px" }, fontWeight: "500" }}
              >
                Gender: {`${userDetails?.gender === 1 ? "Male" : "Female"
                  }`}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                Email: {userDetails?.email || "N/A"}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                DOB: {userDetails?.dob || "N/A"}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "14px", xl: "18px" }, fontWeight: "400" }}
              >
                Location: {userDetails?.location || "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "end", p: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  width: "180px",
                  textTransform: "none",
                  fontSize: { xs: "16px", xl: "20px" },
                  background: "#BEBEBE33",
                  color: "black",
                  "&:hover": {
                    background: "#BEBEBE33",
                    color: "black",
                  },
                }}
              >
                Restrict
              </Button>
              <Button
                sx={{
                  width: "180px",
                  textTransform: "none",
                  fontSize: { xs: "16px", xl: "20px" },
                  background: "#E5000033",
                  color: "#E50000",
                  "&:hover": {
                    background: "#E5000033",
                    color: "#E50000",
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item sx={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={8}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Current Plan
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Race Date :{" "}
                    {userPlanDetails?.created_at
                      ? moment(userPlanDetails?.created_at).format("ll")
                      : "--"}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Goal : {userPlanDetails?.category_names}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Level :{" "}
                    {userPlanDetails?.level === "1"
                      ? "Beginner"
                      : userPlanDetails?.level === "2"
                        ? "Intermediate"
                        : userPlanDetails?.level === "3" && "Advance"}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Plan : {userPlanDetails?.week} Week
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Days : {userPlanDetails?.week_day?.split(", ")?.length} runs
                    per week
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Completed: 8 Week
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                    Runs Completed: 8 Runs
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Strike
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    src={Fire}
                    alt=""
                    style={{ width: "100px", height: "100px" }}
                  />
                  <Typography sx={{ fontSize: { xs: "30px", xl: "52px" } }}>
                    {userDetails?.streak_count || 0}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Challenges
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxSizing: "border-box",
                  }}
                >
                  {challengeEventDetails[0]?.workout_details && challengeEventDetails[0]?.workout_details?.map((elm, index) => {
                    console.log('elm',elm);
                    
                    return (
                      <Box sx={{
                        border: "1px solid #BB9EFF",
                        display: 'flex',
                        alignItems: "center",
                        gridGap: '10px',
                        width: "100%",
                      }} key={index}>
                        <Box sx={{
                          width: '50px',
                        }}>
                          <img src={DummyUserProfit} alt="plan_image" style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }} />
                        </Box>
                        <Box sx={{
                          width: 'calc(100% - 50px)',
                          paddingRight: '5px'
                        }}>
                          <Typography
                            sx={{ mb: "5px", fontSize: { xs: "16px", xl: "20px" } }}
                          >
                            {elm?.name || "N/A"}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                              height: 4,
                              borderRadius: 4,
                              bgcolor: 'grey.200',
                              '& .MuiLinearProgress-bar': {
                                borderRadius: 4,
                                background: "linear-gradient(90deg, #D0BCFF 0%, #B191FF 100%)"
                              }
                            }}
                          />
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: "5px",
                            justifyContent: 'space-between'
                          }}>
                            <Typography
                              sx={{ fontSize: { xs: "10px", xl: "10px" } }}
                            >
                              8 of 20 activities
                            </Typography>
                            <Typography
                              sx={{ fontSize: { xs: "10px", xl: "10px" } }}
                            >
                              End 23 July
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}

                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "16px", xl: "20px" } }}
                >
                  Event
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    background: "#EFE9FF",
                    borderRadius: "15px",
                    p: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxSizing: "border-box",
                  }}
                >
                  <Box sx={{
                    border: "1px solid #BB9EFF",
                    display: 'flex',
                    // alignItems: "center",
                    gridGap: '10px',
                    width: "100%",
                  }}>
                    <Box sx={{
                      width: '100px',
                    }}>
                      <img src={DummyUserProfit} alt="plan_image" style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }} />
                    </Box>
                    <Box sx={{
                      width: 'calc(100% - 100px)',
                      paddingRight: '5px'
                    }}>
                      <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                        Date: June 10, 2024
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                        Route: City Marathon Route
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                        Distance Completed: 5.0 KM
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                        Plan : {userPlanDetails?.week} Week
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "16px", xl: "20px" } }}>
                        Pace: 5:00 min/km
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default UserDetails;
