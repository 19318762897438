import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from "yup";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from '../../../Components/CustomAction/CustomAction';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import Constant from '../../../Utils/Constant';
import { toast } from 'react-toastify';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import Swal from 'sweetalert2';
const VideoCategory = () => {
  // const pageSize = 30;
  // const [page, setPage] = useState(0);
  // const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (params?.row?.category_name ? params?.row?.category_name : "--")
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <CustomAction
              showDelete={true}
              showEdit={true}
              isDelete={() => handleDeleteCategory(params?.row?.id)}
              isEdit={() => handleEditCategory(params?.row)}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getCategoryList();
  }, [])
  // const getCategoryList = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await AxiosInstance.get(APILIST.GET_CATEGORY, {
  //       params: {
  //         count: page * pageSize,
  //         offset: pageSize,
  //       }
  //     });
  //     if (response && response?.data?.s) {
  //       const Rowdata = response?.data?.r?.map((data, i) => ({
  //         rowid: i + 1 + page * pageSize,
  //         ...data,
  //       }));
  //       setCategoryList(Rowdata);
  //       setCount(response?.data?.c || 0);
  //     } else {
  //       setCategoryList([]);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }
  const getCategoryList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_CATEGORY);
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1,
          ...data,
        }));
        setCategoryList(Rowdata);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }
  const handlePageChange = (newPage) => {
    // setPage(newPage);
  };
  const handleDeleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            APILIST.DELETE_CATEGORY,
            params
          );
          if (response?.data?.s === 1) {
            getCategoryList();
            toast.success("Category Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete category. Please try again.");
        }
      }
    });
  };
  const handleEditCategory = (cat) => {
    console.log('cat', cat);
    if (cat) {
      setTypeCheck('EDIT');
      setEditCategoryId(cat?.id)
      formik.setValues({
        categoryName: cat?.category_name || ""
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryName: "",
    },
    validationSchema: Yup.object().shape({
      categoryName: Yup.string().required("Category name is required").matches(Constant.REGEX.ALPHANUMERIC, "Please use only letters, spaces, apostrophes, and hyphens."),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();
          formData.append('category_name', values.categoryName);
          if (typeCheck === 'EDIT') {
            formData.append('id', editCategoryId);
            const response = await AxiosInstance.post(APILIST.UPDATE_CATEGORY, formData);
            if (response?.data?.s) {
              toast.success('Category Updated Successfully!');
            }
          } else {
            const response = await AxiosInstance.post(APILIST.ADD_CATEGORY, formData);
            if (response?.data?.s) {
              toast.success('Category Added Successfully!');
            }
          }
          resetForm();
          getCategoryList();
          setTypeCheck(null);
        } catch (error) {
          toast.error('Failed to save category. Please try again.');
        }
      }
    },
  });
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '16px'
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
            }}
          >
            Category Management
          </Typography>
        </Box>
        <Box sx={{ padding: "16px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <form onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}>
            <Grid container spacing={0} alignItems={'center'}>
              <Grid item xs={8}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  gridGap: '20px',
                  flexDirection: 'row'
                }}>
                  <Box sx={{
                    width:'100%'
                  }}>
                    <CustomTextField
                      type="text"
                      placeholder="Enter Category"
                      name="categoryName"
                      value={formik.values.categoryName}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          border: "1px solid #BB9EFF",
                          color: "black",
                          fontSize: "18px",
                          background: "white",
                          fontFamily: "Outfit",
                          height: '54px',
                        },
                        mt: "0px"
                      }}
                      style={{ color: "black", fontSize: "18px" }}
                    />
                  </Box>
                  <CustomSubmitButton
                    btnTitle={typeCheck ? "Update Category" : "Add Category"}
                    loading={isLoading}
                    disabled={!(formik.dirty && formik.isValid)}
                    type="submit"
                    sx={{
                      width: "180px",
                      borderRadius: "15px",
                      textTransform: "none",
                      fontSize: "16px",
                      background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                      color: "black",
                      padding: "0",
                      margin: "0",
                      height: '54px',
                      "&:hover": {
                        background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                        color: "black",
                      },
                      fontFamily: "Outfit",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>

          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={categoryList}
              columns={columns}
              loading={isLoading}
              isPagination={false}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default VideoCategory;
