import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PROFILE_PHOTO_USER from '../../../Assets/Images/Common/avtar.png'
import CustomAction from '../../../Components/CustomAction/CustomAction';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import Constant from '../../../Utils/Constant';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../Components/useDebounce/useDebounce';
import CustomSearch from '../../../Components/CustomSearch/CustomSearch';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
const RunnerPlan = () => {
 
  const navigate = useNavigate();
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [runnerPlanList, setRunnerPlanList] = useState([]);
  const [searchRunnerPlan, setSearchRunnerPlan] = useState("");
  const debouncedSearch = useDebounce(searchRunnerPlan, 500);
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("0");
  const [planLevelList, setPlanLevelList] = useState([
    { name: "All", label: "All" },
    {
      id: '1',
      name: "beginner",
      label: "beginner"
    },
    {
      id: '2',
      name: "intermediate",
      label: "intermediate"
    },
    {
      id: '3',
      name: "advanced",
      label: "advanced"
    },
  ])
  const [selectPlanLevel, setSelectPlanLevel] = useState("0");
  const [weekList, setWeekList] = useState([
    { name: "All", label: "All" },
    { id: "1", name: "1 Week", label: "1" },
    { "id": "2", name: "2 Week", label: "2" },
    { "id": "3", name: "3 Week", label: "3" },
    { "id": "4", name: "4 Week", label: "4" },
    { "id": "5", name: "5 Week", label: "5" },
    { "id": "6", name: "6 Week", label: "6" },
    { "id": "7", name: "7 Week", label: "7" },
  ]
  );
  const [selectWeek, setSelectWeek] = useState("0");
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "cover_img",
      headerName: "Banner",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <img src={params?.row?.cover_img ? `${Constant.BASE_URL}${params?.row?.cover_img}` : PROFILE_PHOTO_USER} alt='profile_photo' style={{
            width: '40px',
            height: '40px',
            borderRadius: '10px'
          }} />
        )
      }
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 250,
      renderCell: (params) => (params?.row?.title ? params?.row?.title : "--")
    },
    {
      field: "level",
      headerName: "Level",
      minWidth: 150,
      renderCell: (params) => {
        const levelLabels = {
          1: "Beginner",
          2: "Intermediate",
          3: "Advanced"
        };
        return params?.row?.level ? levelLabels[params.row.level] || "--" : "--";
      }

    },
    {
      field: "week",
      headerName: "Duration",
      minWidth: 150,
      renderCell: (params) => (params?.row?.week ? `${params?.row?.week} Week` : "--")
    },
    {
      field: "category_names",
      headerName: "Category",
      minWidth: 300,
      renderCell: (params) => (params?.row?.category_names ? params?.row?.category_names : "--")
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 400,
      renderCell: (params) => {
        const description = params?.row?.description || '--';
        const previewText = description.length > 40 ? description.substring(0, 40) + '...' : description;
        return (
          <Tooltip title={description} arrow>
            <span>{previewText}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <CustomAction
              showDelete={true}
              showEdit={true}
              isEdit={() => navigate('/admin/edit-runner-plan', {
                state: {
                  type: 'Edit',
                  runnerPlanId: params?.row?.id
                }
              })}
              isDelete={() => handleDeleteRunnerPlan(params?.row?.id)}
            />
          </Box>
        );
      },
    },
  ];
  const getCategoryList = async () => {
    try {
      const response = await AxiosInstance.get(APILIST.GET_PLAN_TYPE);
      if (response && response?.data?.s) {
        const categories = response?.data?.r;
        const allOption = { name: "All", category_name: "All" };
        setCategoryList([allOption, ...categories]);
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  useEffect(() => {
    getCategoryList();
  }, [])
  useEffect(() => {
    getRunnerPlanList();
  }, [page, debouncedSearch, selectCategory, selectPlanLevel, selectWeek]);
  const getRunnerPlanList = async () => {
    setIsLoading(true);
    try {
      const params = {
        count: page * pageSize,
        offset: pageSize,
        is_admin: 1
      };

      if (debouncedSearch) params.search = debouncedSearch;
      if (selectCategory !== "0") params.cat_id = selectCategory;
      if (selectPlanLevel !== "0") params.level = selectPlanLevel;
      if (selectWeek !== "0") params.week = selectWeek;
      const response = await AxiosInstance.get(APILIST.GET_PLAN, { params });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setRunnerPlanList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setRunnerPlanList([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleDeleteRunnerPlan = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this runner plan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            APILIST.DELETE_PLAN,
            params
          );
          if (response?.data?.s === 1) {
            getRunnerPlanList();
            toast.success("Runner Plan Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete runner plan. Please try again.");
        }
      }
    });
  };
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '16px'
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
            }}
          >
            Runner Plan
          </Typography>
          <Button
            sx={{
              width: "120px",
              borderRadius: "15px",
              textTransform: "none",
              fontSize: "16px",
              background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
              color: "black",
              padding: "0",
              margin: "0",
              height: '51px',
              "&:hover": {
                background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                color: "black",
              },
              fontFamily: "Outfit",
            }}
            onClick={() => navigate('/admin/add-runner-plan', {
              state: {
                type: "ADD"
              }
            })}
          >
            Add Plan
          </Button>
        </Box>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} lg={5}>
              <CustomSearch
                placeholder="Search Plan"
                value={searchRunnerPlan}
                onChange={(event) => setSearchRunnerPlan(event.target.value)}
                onClear={() => setSearchRunnerPlan("")}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <CustomDropDown
                name='selectCategory'
                // Title={"Select Plan Type"}
                value={selectCategory}
                onChange={(e) => setSelectCategory(e.target.value)}
                placeholder={'Select Category'}
                menuList={categoryList}
                NoData='No Category Available'
                isLoading={isLoading}
                getLabel={(item) => item}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <CustomDropDown
                name='planLevel'
                // Title={"Plan Level"}
                value={selectPlanLevel}
                onChange={(e) => setSelectPlanLevel(e.target.value)}
                placeholder={'Select Level'}
                menuList={planLevelList}
                NoData='No Plan Level Available'
                isLoading={isLoading}
                getLabel={(item) => item}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <CustomDropDown
                // Title={"Type"}
                value={selectWeek}
                onChange={(e) => setSelectWeek(e.target.value)}
                placeholder={'Select Week'}
                menuList={weekList}
                NoData='No Week Available'
                isLoading={isLoading}
                getLabel={(item) => item.name}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={runnerPlanList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
              isPagination={true}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RunnerPlan;
