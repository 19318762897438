import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Chip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from '../../../Components/CustomAction/CustomAction';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { useNavigate } from 'react-router-dom';
import AddWorkOutTitleModel from './AddWorkoutDaysModel';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
const AllWorkOutPlan = () => {
  const navigate = useNavigate();
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allWorkoutList, setAllWorkoutList] = useState([]);
  const [workoutTitleModel, setWorkoutTitleModel] = useState(false)
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (params?.row?.title ? params?.row?.title : "--")
    },
    {
      field: "week",
      headerName: "Duration",
      minWidth: 100,
      renderCell: (params) => (params?.row?.week ? `${params?.row?.week} Week` : "--")
    },
    {
      field: "day",
      headerName: "Days",
      minWidth: 100,
      renderCell: (params) => (params?.row?.day ? `${params?.row?.day} Day` : "--")
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: (params) => {
        const status = params?.row?.status;
        let chipLabel = "";
        let chipColor = "";
        if (status === 1) {
          chipLabel = "Publish";
          chipColor = "green";
        } else if (status === 0) {
          chipLabel = "Draft";
          chipColor = "gray";
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <Chip
              label={chipLabel}
              sx={{
                backgroundColor: chipColor,
                color: "#000",
                padding:'5px 10px'
              }}
            />
          </Box>
        );
      },
    },    
    {
      field: "action",
      headerName: "Actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <CustomAction
              showDelete={true}
              showEdit={true}
              isDelete={() => handleDeleteWorkout(params?.row?.id)}
              isEdit={() => navigate("/admin/edit-workout", {
                state: {
                  type: 'Edit',
                  workOutPlanID: params?.row?.id
                }
              })}
            />
          </Box>
        );
      },
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleOnSubmit = (data) => {
    setWorkoutTitleModel(false);
    navigate('/admin/add-workout', {
      state: {
        type: "ADD",
        workOutPlanID: data?.id
      }
    });
  }
  useEffect(() => {
    getAllWorkoutList();
  }, [page])
  const getAllWorkoutList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance(APILIST.GET_ALL_WORKOUTS, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          is_admin:1
        }
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setIsLoading(false);
        setAllWorkoutList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setAllWorkoutList([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  }
  const handleDeleteWorkout = async (workoutPlanID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this workout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", workoutPlanID);
        formData.append("status", -1);
        try {
          const response = await AxiosInstance.post(
            APILIST.UPDATE_DELETE_WORKOUT,
            formData
          );
          if (response?.data?.s === 1) {
            getAllWorkoutList();
            toast.success("Workout Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete workout. Please try again.");
        }
      }
    });
  }
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '16px'
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
            }}
          >
            Workout Plan
          </Typography>
          <Button
            sx={{
              width: "150px",
              borderRadius: "15px",
              textTransform: "none",
              fontSize: "16px",
              background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
              color: "black",
              padding: "0",
              margin: "0",
              height: '51px',
              "&:hover": {
                background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                color: "black",
              },
              fontFamily: "Outfit",
            }}
            onClick={() => setWorkoutTitleModel(true)}
          >
            Add Workout
          </Button>
        </Box>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={allWorkoutList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
              isPagination={true}
            />
          </Box>
        </Box>
      </Box>
      <AddWorkOutTitleModel
        open={workoutTitleModel}
        onClose={() => setWorkoutTitleModel(false)}
        handleOnClose={() => setWorkoutTitleModel(false)}
        handleOnSubmit={handleOnSubmit}
      />
    </Fragment>
  );
};

export default AllWorkOutPlan;
